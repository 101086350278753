import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Section from 'ui-kit/section';
import Container from 'ui-kit/container';
import Image from 'ui-kit/image';
import Stage from 'ui-kit/stage';
import Intro from 'ui-kit/intro';
import { P, Headline, Highlight } from 'ui-kit/typography';
import { getImageFromNode } from 'helpers/utils';
import { nodesOf } from 'helpers/prop-types';

import Layout from '../../components/layout';
import TeaserSection from '../../components/teaser-section';

const WorkWithUsPage = ({ data }) => {
    const { contentImages } = data;

    /** Bilder */
    const stageImage = data.stageImage.childImageSharp;

    return (
        <Layout description="Arbeiten bei uns">
            <Stage
                image={stageImage}
                imageAlt="Wanderer blickt auf Berggipfel"
                tag="Karriere | Arbeiten bei uns"
                headline={{ text: 'Bei uns kommen Sie voran', level: 'h1' }}
                bottomShade
            />
            <Section>
                <Intro title="Willkommen im Team">
                    <P fontSize="m">
                        Egal ob Sie gestandener Profi sind oder noch ganz am Anfang Ihres
                        Berufslebens stehen – die Südbayerische Fleischwaren GmbH bietet sichere,
                        interessante Arbeits- und Ausbildungsplätze mit guten Entwicklungs- und
                        Aufstiegsmöglichkeiten. Werden Sie Teil eines starken Teams, ziehen Sie mit
                        uns an einem Strang und bringen Sie Ihre Ideen mit ein
                    </P>
                    <Highlight>– denn gemeinsam kommen wir am besten voran!</Highlight>
                </Intro>
            </Section>
            <Section bg="gray">
                <Container size="l" gap="xxxxl" grid layout="6-6" cols="2" alignItems="center">
                    <Image
                        {...getImageFromNode(
                            contentImages,
                            'career-page/inhaltsbild-gemeinsam-ans-ziel.jpg'
                        ).node.childImageSharp}
                        alt="Gruppe von Wanderern auf dem Weg zum Gipfel"
                        layer={1}
                        gap={{ small: 'xxl', medium: 'none' }}
                    />
                    <div>
                        <Headline level="h3">Zufriedene Mitarbeiter sind uns wichtig</Headline>
                        <P>
                            Ein Unternehmen ist immer nur so gut wie seine Mitarbeiter. Deshalb
                            setzen wir auf ein starkes Team aus zufriedenen Mitarbeitern. Nur wer
                            sich in seinem Umfeld wohl fühlt, kann seine Stärken wirklich entfalten,
                            das Team optimal unterstützen und zum gemeinsamen Erfolg beitragen. Gute
                            Gründe für uns, Eigeninitiative, Verantwortungs- und
                            Leistungsbereitschaft zu fördern und uns aktiv für die Zufriedenheit
                            unserer Mitarbeiter einzusetzen.
                        </P>
                    </div>
                </Container>
                <Container size="l" grid layout="6-6" cols="2" alignItems="center" reverseOnMobile>
                    <div>
                        <Headline level="h3">Vorbildlich bei Aus- und Weiterbildung</Headline>
                        <P>
                            Eine gute Ausbildung, zum Beispiel zum Fleischer oder zur Fleischerin
                            oder auch im kaufmännischen Bereich ist die solide Basis für ein
                            erfolgreiches Berufsleben. Mit entsprechenden Weiterbildungsmaßnahmen
                            wie dem Besuch der Meisterschule oder einer Zusatzausbildung zum
                            Betriebswirt ist es möglich, sich erfolgreich im Unternehmen
                            weiterzuentwickeln. Bei uns ist alles möglich – vom Lehrling bis zur
                            Führungskraft. Unser Betriebsleiter ist das beste Beispiel dafür.
                        </P>
                    </div>
                    <Image
                        {...getImageFromNode(
                            contentImages,
                            'career-page/inhaltsbild-ausgezeichnete-aus-und-weiterbildung.jpg'
                        ).node.childImageSharp}
                        alt="Urkunde Ausbildungsbetrieb 2018"
                        layer={1}
                        gap={{ small: 'xxl', medium: 'none' }}
                    />
                </Container>
            </Section>
            <TeaserSection
                headline="Jetzt informieren und erfolgreich durchstarten"
                boxes={['apprenticeship', 'jobOffers']}
            />
        </Layout>
    );
};

WorkWithUsPage.propTypes = {
    data: PropTypes.shape({
        stageImage: PropTypes.object,
        contentImages: nodesOf(PropTypes.object),
    }).isRequired,
};

export const query = graphql`
    query {
        stageImage: file(relativePath: { eq: "career-page/buehne-karriere.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        contentImages: allFile(
            filter: { relativePath: { glob: "career-page/inhaltsbild-*.jpg" } }
        ) {
            edges {
                node {
                    relativePath
                    childImageSharp {
                        fluid(maxWidth: 605, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`;

export default WorkWithUsPage;
